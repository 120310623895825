.top-info-wrapper {
  position: fixed;
  top: 0;
  z-index: 11;
  display: block;
  width: 100%;
  background-color: white;

  .top-info {
    width: 100%;
    padding: 1rem;
    background: lighten(#83bc23, 55%);
    border: 1px solid #83bc23;
    border-radius: 5px;
    margin-bottom: -7vh;
    margin-top: 4.5rem;
    color: #83bc23;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;

    a {
      margin-left: auto;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        margin-bottom: 0.5rem;
      }

      a {
        margin-left: 0;
      }
    }
  }
}
