.promo {
  margin-top: 1rem;

  &-header {
    width: 100%;
    // height: 45vh;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  .promo-list {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem 2rem;
    padding-bottom: 2rem;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 574px) {
      grid-template-columns: 1fr;
    }
  }
}
