.signup {
  &-pane {
    margin: 2rem auto 4rem;
    // border: 1px solid #c5c5c5;
    border-radius: 5px;
    box-shadow: 0 0 5px #dddddd;
    padding: 0 !important;
    max-width: 400px;

    .signup-title {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid #ddd;

      h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #555;
      }
    }
  }
}

.signup-form {
  padding: 1rem 1.5rem;

  .terms-and-policy {
    label {
      font-size: 0.8rem;
      font-weight: bold;

      input {
        margin-right: 0.5rem;
      }

      a {
        color: #83BC23;
      }
    }

    margin-bottom: 1rem;
  }

  .show-pass {
    position: absolute;
    right: 20px;
    bottom: 22px;
    color: #777;
    outline: none;
  }

  .submit {
    button {
      width: 100%;
      height: 3.5rem;
    }
  }
}

.recaptcha {
//   margin: 1rem 0 1.5rem;
//   margin-left: 140px;
}

.signup-bottom {
  padding: 1rem 0;
  border-top: 1px solid #ddd;
  a {
    display: block;
    text-align: center;
    font-size: 0.8rem;
    color: #21c0fc;
  }
}

.form-group {
  .label {
    margin-bottom: 1rem;
  }

  input {
    outline: none !important;
    // font-size: 14px;
  }

  input.red {
    border: 1px solid rgb(252, 163, 163);
    box-shadow: 0px 0px 2px rgb(253, 155, 155);
  }

  .error-msg {
    color: red;
    font-size: 11px;
  }
}
