.promo-card {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 5px;
  height: 220px;
  background-color: #fff;

  &.ended {
    position: relative;

    &::after {
      content: '';
      padding-top: 25%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgb(175, 171, 171);
      border-radius: 5px;
      opacity: 0.85;
    }

    &::before {
      content: 'Promo Ended';
      font-size: 2rem;
      font-weight: 500;
      width: 100%;
      text-align: center;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.shadowed {
    box-shadow: 0 1.4px 2.2px rgba(127, 127, 127, 0.034),
      0 3.4px 5.3px rgba(158, 158, 158, 0.048),
      0 7.5px 10px rgba(80, 80, 80, 0.06),
      0 14.3px 17.9px rgba(146, 146, 146, 0.072),
      0 30.8px 33.4px rgba(125, 125, 125, 0.086);
  }

  &.bordered {
    border: 1px solid #adadad;
  }

  .card-left {
    width: 70%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &-text {
      padding: 1rem;
      display: block;
      flex: 1 0 auto;

      h4 {
        margin-bottom: 2rem;
        font-size: 1.2rem;
      }

      p {
        @media screen and (max-width: 400px) {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* number of lines to show */
        }
      }
    }

    .promo-price {
      padding: 1rem;
      border-bottom-left-radius: 5px;

      .currency-price {
        font-weight: 500;
      }
    }
  }

  img {
    max-width: 30%;
    object-fit: cover;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    max-height: 100%;
  }
}
