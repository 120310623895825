@import './normalize';
// @import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600,700&display=swap");
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mali:wght@700&display=swap');

$tera-smoke: #f5f5f5;
$tera-blue: #21c0fc;
$tera-green: #83bc23;
$tera-black: #222222;
$tablet-view: 'screen and (min-width: 580px) and (max-width: 989px)';
$desktop-view: 'screen and (min-width: 990px)';

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  font-size: 14px;
}

body {
  font: 400 16px 'Inter', 'sans-serif';
  line-height: 1.4;
  color: #222;

  & > a {
    display: none;
  }

  #comodoTL {
    display: none;
  }
}

.inter {
  font: 400 16px 'Inter', sans-serif;
  line-height: 26px;
  color: #18191f;
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0;

  // @media screen and (min-width: 990px) {
  //   font-size: 15px;
  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  @media screen and (min-width: 990px) {
    font-size: 15px;
  }
}

//Due to iphone input focus zoom
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
select:focus,
textarea {
  font-size: 16px !important;
  appearance: none;
}

select {
  appearance: none;
  color: $tera-black;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.mg-rl-auto {
  margin: 0 auto;
  display: block;
}

.off-white {
  background: #fafafa !important;
}

//Utilities

.u-center {
  display: block;
  & > * {
    display: block;
    margin: 0 auto;
  }
}

// .container {
//     width: 100%;
// }

.container-width {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: 768px) {
    width: 750px;
  }

  @media screen and (min-width: 992px) {
    width: 970px;
  }

  @media screen and (min-width: 1200px) {
    width: 1170px;
  }

  .row-width {
    margin-right: -1rem;
    margin-left: -1rem;
  }
}

/*  COMPONENTS */

.discount {
  background: #ed8a1935;
  color: #ed8a19;
  font-size: 0.8rem;
  padding: 2px 3px;
  border-radius: 4px;
  margin-left: 1rem;

  &.large {
    font-size: 1.2rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

//Tooltip
.tooltip-holder {
  position: relative;
  // width: fit-content;
  margin: 0;

  .tool-tip {
    visibility: hidden;
    width: 200px;
    background-color: #222;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 200;
    bottom: 150%;
    left: 50%;
    transform: translateX(-25%);
    margin-left: -60px;
    display: block;
    font-size: 0.85rem;

    &.object {
      bottom: 110%;
      margin-left: 25%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: #222 transparent transparent transparent;
    }
  }

  &:hover {
    .tool-tip {
      visibility: visible;
    }
  }
}

.no-butn {
  background: none;
  border: none;
}

.butn,
.square-butn {
  padding: 0.4rem 1rem;
  border-radius: 50px;
  transition: 0.4s all ease-in-out;
  color: #fff;
  font-weight: bold;
  outline: none !important;
  white-space: nowrap;
  cursor: pointer;

  &-small {
    padding: 0 1rem;
  }

  &--disabled {
    background: #ddd;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }

  &--grey {
    background: #989898;
    border: 1px solid #989898;
  }

  &--red {
    background-color: red;
    border: 1px solid red;

    &:hover,
    &:focus {
      background-color: white !important;
      color: red;
    }

    &--outline {
      border: 1px solid red;
      color: red;
      background: white;

      &:hover,
      &:focus {
        background: red !important;
        color: white;
      }
    }
  }

  &--black {
    background: $tera-black;
    border: 1px solid $tera-black;

    &:hover,
    &:focus {
      background: #fff;
      color: $tera-black;
    }

    &-outline {
      background: #fff;
      color: $tera-black;
      border: 1px solid $tera-black;

      &:hover,
      &:focus {
        background-color: $tera-black !important;
        color: #fff;
      }
    }
  }

  &--green {
    background: $tera-green;
    border: 1px solid $tera-green;
    &:hover,
    &:focus {
      background: #fff;
      color: $tera-green;
    }

    &-line {
      background: #fff;
      color: $tera-green;
      border: 1px solid $tera-green;
    }

    &--outline {
      background: white;
      color: $tera-green;
      border: 1px solid $tera-green;

      &:hover,
      &:focus {
        background: $tera-green;
        color: #fff;
      }
    }
  }

  &--blue {
    background: $tera-blue;
    border: 1px solid $tera-blue;
    &:hover,
    &:focus {
      background: #fff;
      color: $tera-blue;
    }

    &--outline {
      background: white;
      border: 1px solid $tera-blue;
      color: $tera-blue;

      &:hover,
      &:focus {
        background: $tera-blue;
        color: white;
      }
    }
  }

  &--white {
    background: white;
    border: 1px solid white;
    color: $tera-blue;

    &:hover,
    &:focus {
      background: transparent;
      color: white;
    }

    &--outline {
      background: transparent;
      border: 1px solid white;
      color: white;

      &:hover,
      &:focus {
        background: white;
        color: $tera-blue;
      }
    }
  }

  &.o-h {
    width: 130px;
    padding: 0.4rem;
    text-align: center;
  }
}

.square-butn {
  border-radius: 5px;
}

//Pointer
.pointer {
  cursor: pointer;
}

/*Swal*/
.swal-text {
  color: #222;
  line-height: 1.8;
  font-weight: 300;
  font-size: 14px;
}

.about-edit {
  .ql-editor {
    height: 150px;
  }

  .about-buttons {
    display: block;
    margin: 1rem 0 0;
  }

  button {
    // display: block;
    margin-right: 0.5rem;
  }
}

/* Toasts */

.toast {
  &-success-container {
    background: #38a169 !important;
    color: white;
  }

  &-error-container {
    background: #e53e3e;
    color: white;
  }

  &-info-container {
    background: '#3182CE';
    color: white;
  }

  &-warning-container {
    background: '#DD6B20';
    color: white;
  }
}

/*Toast*/

a.links-more {
  color: $tera-blue;
  font-size: 0.9rem;
}

.section_title {
  max-width: 99%;
  margin: 0 auto 2rem;
  &--text {
    text-align: center;
    font-size: 1.3rem;
  }
  &--divider {
    width: 50px;
    margin: 0.7rem auto;
    border-bottom: 5px solid $tera-green;
  }
}

/* COMPONENTS ENDS*/

//RIch Text Content
.rich-content {
  ul {
    margin-left: 1rem;
    li {
      list-style: disc !important;
    }
  }

  p {
    height: fit-content;
    margin: 1rem 0;
  }
}

/*Common*/

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-bg {
  background-color: #83bc23;
  color: white !important;

  a {
    color: white;
  }
}

.amber-bg {
  background-color: #ffc200;
  color: white !important;
}

.red-bg {
  background-color: red;
  color: white !important;
}

.grey-text {
  color: #999 !important;
}

.green-text {
  color: #37a000 !important;
}
.red-text {
  color: red !important;
}
.amber-text {
  color: #ffd700 !important;
}
.gold-text {
  color: #ffbe5b !important;
}
.blue-text {
  color: #21c0fc !important;
}

.black-text {
  color: #222;
}

.jc-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-space {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
}

.bold {
  font-weight: 500;
}

.user-private,
.float-wrapper {
  margin: 1rem 0 2rem;

  .floater {
    width: 340px;
    font-size: 14px;
    float: left;

    @media screen and (max-width: 767px) {
      width: 100%;
      float: none;
      margin-bottom: 1.5rem;
      display: block;
    }
  }

  .dashboard-right,
  .floater-sibling {
    margin-left: 355px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

/*Vendors*/

.react-datepicker-wrapper {
  display: block;
}

.react-tel-input {
  input {
    padding-left: 3.2rem !important;
  }
}

.no-swal-hover {
  &.swal-button:not([disabled]):hover {
    background-color: white;
  }
}

.modal-body {
  font-weight: 300;
}

.slicky {
  .slick-slide {
    padding-right: 20px;
  }
  .slick-list {
    padding-right: -20px;
  }
  .slick-prev,
  .slick-next {
    &::before {
      color: black;
    }
  }
  .slick-dots {
    bottom: 0;
  }
}

main.page.off-white {
  border-bottom: 1px solid #ddd;
}

.page {
  min-height: 90vh;
  width: 100%;
  background: #fff;
  position: relative;

  h2 {
    font-size: 15px;
    margin-bottom: 1rem;
  }
  // &.terms {
  //     .page-main {
  //         background: #f5f5f5;
  //     }
  // }

  //Skill pill
  //TO be extracted to a single file

  .skill-pill {
    background: white;
    border: 1px solid #222;
    color: #222;
    border-radius: 5px;
    font-weight: 400;
    margin-right: 1rem;
    transition: 0.4 ease all;
    margin-bottom: 1rem;
    padding: 0.5rem;

    &:hover {
      background: #222222;
      color: white;
    }
  }

  /*How-It_works style Start*/

  .top_banner {
    padding: 3rem;
    background: $tera-smoke;

    &--text {
      text-align: center;

      &-main {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  .toggle {
    width: 100%;
    padding: 2rem 0;

    &--switch {
      width: 180px;
      margin: 0 auto;

      @media screen and (min-width: 990px) {
        width: 220px;
      }

      &--button {
        width: 40%;
        display: inline-block;
        background: #c5c5c5;
        text-align: center;
        padding: 0.5rem 1.5rem;
        color: #fff;
        cursor: pointer;
        transition: 0.3s ease all;

        &.buy {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &.sell {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          &.active {
            background: $tera-blue;

            &::after {
              border-top: 12px solid $tera-blue;
            }
          }
        }

        &.active {
          width: 60%;
          background: $tera-green;
          font-weight: bold;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            right: 50%;
            transform: translateX(50%);
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 12px solid $tera-green;
          }
        }
      }
    }
  }

  .hiw_content {
    padding: 2rem 0;
    position: relative;
    padding-bottom: 5rem;

    &--item {
      padding: 1rem;
      position: relative;

      @media screen and (min-width: 990px) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:nth-of-type(even) {
          flex-direction: row-reverse;
        }

        .content_number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          color: $tera-blue;
          font-size: 1.2rem;
          padding: 0.6rem 1rem;
          border: 2px solid $tera-blue;
          border-radius: 50%;
          background: #fff;
          z-index: 1;

          &.seller {
            color: $tera-green;
            border: 2px solid $tera-green;
          }
        }
      }

      .image-wrapper {
        // width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 990px) {
          display: block;
        }
      }

      .place_image {
        width: 200px;
        // max-width: 300px;
        margin: 0 auto;

        @media #{$tablet-view} {
          width: 250px;
        }

        @media #{$desktop-view} {
          width: 320px;
        }
      }

      &-text {
        padding: 1rem 0;
        transition: 0.1s ease all;

        @media screen and (min-width: 990px) {
          max-width: 400px;
        }

        h3 {
          margin-bottom: 0.5rem;
        }

        ul {
          list-style-position: inside;
        }

        li {
          list-style: disc;
          margin-left: 1rem;
          margin-bottom: 1rem;
        }

        &-title {
          font-size: 1.3rem;
          text-align: center;
          margin-bottom: 1rem;
        }

        &-sub {
          text-align: justify;
        }

        .expanded {
          display: none;
          transition: 0.1s ease all;

          &.show {
            display: unset;
          }
        }

        .read-more {
          display: block;
          text-align: right;
          cursor: pointer;
          font-size: 0.8rem;
          color: $tera-blue;
        }
      }
    }

    @media screen and (min-width: 990px) {
      .hiw_content--item:last-child {
        flex-direction: column !important;
        margin-top: 10rem;

        .content_number {
          top: -50px;
        }
      }
    }
  }

  .mid_line {
    height: 62%;
    width: 1px;
    border-left: 2px solid transparent;
    position: absolute;
    top: 11%;
    left: 50%;
    transform: translateX(-50%);

    &.seller {
      border-left: 2px solid transparent;
    }
  }

  .mini_faq {
    padding: 3rem 2rem;

    .faq_list {
      padding: 1rem 0;

      @media screen and (min-width: 990px) {
        padding: 1rem 3rem;
      }

      &--item {
        border-top: 1px solid rgb(189, 189, 189);
        padding: 1rem 0;
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        transition: 0.5s all ease;
        cursor: pointer;

        &:last-child {
          border-bottom: 1px solid rgb(189, 189, 189);
        }

        .chev-up {
          display: none;
          color: #999;
        }

        .chev-down {
          color: #999;
        }

        &.open {
          padding-bottom: 5rem;

          .faq--text-main {
            display: unset;
          }

          .chev-down {
            display: none;
          }

          .chev-up {
            display: unset;
          }
        }

        .faq--text {
          &-header {
            font-size: 1.1rem;
            margin-bottom: 1rem;
          }

          &-main {
            display: none;
          }
        }
      }
    }
  }

  /*How-It_works style ends*/

  /*Search Page*/

  &-title {
    width: 100%;
    background: #fff;
    padding: 1rem 0rem;
    border-top: 1px solid black;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 11;
    text-transform: uppercase;

    @media screen and (min-width: 990px) {
      padding: 2rem;
    }

    h1 {
      font-weight: 700;
      font-size: 1.2rem;

      &.capitalize {
        text-transform: capitalize;
        font-size: 1.3rem;

        span {
          text-transform: uppercase;
        }
      }

      span {
        text-transform: uppercase;
      }
    }
  }

  .main {
    padding: 2rem 0;

    @media screen and (min-width: 990px) {
      padding: 2rem 0;
    }
  }

  &-main {
    background: #fafafa;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 2rem 0;

    @media screen and (min-width: 990px) {
      padding: 2rem 0;
    }

    .terms-article,
    .about-article {
      margin-bottom: 3rem;

      h2 {
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
      }
      p {
        text-align: justify;
      }
    }

    .terms-article {
      ol {
        list-style-type: lower-alpha;
        margin-bottom: 1rem;

        li {
          list-style-type: lower-alpha;
          margin-left: 1.5rem;
        }
      }

      a {
        color: blue;
      }
    }

    .about-article {
      margin-bottom: 2rem;
      font-size: 18px;

      ol {
        li {
          list-style: decimal inside;
        }
      }
    }

    .terms-time {
      font-size: 0.7rem;
      text-align: right;
    }

    .about-page-header {
      height: 150px;
      width: 100%;
      background-image: linear-gradient(
          to right bottom,
          rgba(lighten($tera-blue, 40%), 0.8),
          rgba(lighten($tera-blue, 35%), 0.8)
        ),
        url('../img/headers/about-header.jpg');
      background-position: center;
      margin-bottom: 2rem;
      text-align: center;
      position: relative;

      @media #{$tablet-view} {
        height: 200px;
      }

      @media #{$desktop-view} {
        height: 250px;
      }

      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        .about-title {
          font-size: 1.2rem;
          text-transform: uppercase;
          margin-bottom: 1rem;

          @media #{$tablet-view} {
            font-size: 1.8rem;
          }

          @media #{$desktop-view} {
            font-size: 2rem;
          }
        }

        .sub {
          font-weight: 400;
          font-size: 18px;
          @media #{$desktop-view} {
            font-weight: 500;
          }
        }
      }
    }

    .about-page-container {
      display: block;

      @media #{$tablet-view} {
        padding: 0 2rem;
      }

      @media #{$desktop-view} {
        padding: 0 4rem;
      }

      .address {
        font-size: 18px;
      }
    }
  }

  .visible-lg {
    display: none;

    @media screen and (min-width: 990px) {
      display: block;
    }
  }

  .visible-md {
    display: unset;

    @media screen and (min-width: 990px) {
      display: none;
    }
  }

  .visible-sm {
    display: unset;

    @media screen and (min-width: 766px) {
      display: none;
    }
  }

  .visible-mobile {
    display: unset;

    @media screen and (min-width: 577px) {
      display: none;
    }
  }

  .visible-small {
    display: none;

    @media screen and (min-width: 577px) {
      display: unset;
    }
  }

  .form-error {
    color: red;
    font-size: 0.9rem;
  }

  .no-pad-tb {
    padding-top: 0;
    padding-bottom: 0;
  }

  .no-pad-lr {
    padding-left: 0;
    padding-right: 0;
  }

  .no-pad {
    padding: 0;
  }
}

//THis is to be Extracted to FAQ.scss

.faq_list {
  padding: 1rem 0;

  @media screen and (min-width: 990px) {
    padding: 1rem 0;
  }

  &--item {
    border-top: 1px solid rgb(189, 189, 189);
    padding: 1rem 0;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    transition: 0.3s all ease;
    cursor: pointer;

    a {
      color: $tera-blue;
      cursor: pointer;
    }

    &:last-child {
      border-bottom: 1px solid rgb(189, 189, 189);
    }

    .chev-up {
      display: none;
      color: #999;
    }

    .chev-down {
      color: #999;
    }

    &.open {
      padding-bottom: 5rem;

      .faq--text-main {
        display: unset;
      }

      .chev-down {
        display: none;
      }

      .chev-up {
        display: unset;
      }
    }

    .faq--text {
      &-header {
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }

      &-main {
        display: none;
      }
    }
  }
}

.pane-block {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 8px 10px #dddddd, 8px 0 10px #ddd;
  background: white;
  margin-bottom: 1rem;

  .pane-title {
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1.1rem;
    }

    h4 {
      color: #37a000;
    }
  }

  .pane-body {
    padding: 1rem 0;
  }
}

.seller-buyer-toggle,
.tab {
  border-bottom: 1px solid #999;
  margin-bottom: 2rem;

  ul {
    padding: 1rem 0;

    li {
      display: inline;
      padding: 1rem 2rem;
      font-size: 1.1rem;
      cursor: pointer;
      transition: 0.2s ease all;
      border-bottom: 4px solid transparent;

      &.active {
        border-bottom: 4px solid #222;
        padding-bottom: 1.1rem;
        font-weight: 600;
      }

      &.inactive {
        color: #999;
      }

      &:not(.active):hover {
        background-color: #e5e5e5;
        border-bottom: 4px solid #989898;
        padding-bottom: 1.1rem;

        a {
          color: #222;
        }
      }
    }
  }
}

.col-md-20 {
  padding-right: 10px;
  padding-left: 10px;
  @media screen and (min-width: 768px) {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .more-item {
    background: #c5c5c5;
    min-width: 200px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    svg {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    span {
      font-weight: 500;
    }
  }
}

.stack {
  @media screen and (max-width: 991px) {
    display: flex;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }

  &-item {
    @media screen and (max-width: 991px) {
      flex: 0 0 auto;
      max-width: 220px;
    }
  }
}

.new-feature {
  &::after {
    content: 'NEW';
    color: white;
    background: $tera-blue;
    padding: 3px 5px;
    margin-left: 0.3rem;
    font-size: 0.7rem;
    font-weight: 700;
    border-radius: 4px;
  }
}

.job-status {
  padding: 0.4rem 1rem;
  border-radius: 5px;
  text-align: center;
  font-size: 0.95rem;

  &.green {
    background: #83bc2350;
    color: #37a000;
  }

  &.gray {
    background: #eee;
    color: #999;
  }
}

.full-width {
  width: 100%;
}

.headlinesStyle {
  right: -100vw;
  border-left: 1px solid rgb(185, 185, 185);

  .side-content {
    width: 30vw;
    right: 0;

    @media screen and (max-width: 700px) {
      width: 80vw;
    }

    @media screen and (max-width: 400px) {
      width: 95vw;
    }
  }

  &.show {
    right: 0;
  }

  .close {
    left: 20px;
  }

  .bar-title {
    border-bottom: 1px solid #ddd;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close {
      position: static;
    }

    h4 {
      font-size: 1.4rem;
    }
  }

  .sidebar-nav {
    overflow-y: scroll;
    height: 100%;
    padding: 0 1rem 5rem;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 7px;
    }
  }

  .app-nav {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 1rem;
    font-size: 1rem;
    overflow: hidden;
    line-height: 20px;

    &-title {
      padding: 0.7rem 1rem;
      background-color: #83bc2330;
      color: $tera-black;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &.blue,
      &.seller {
        background-color: #21c0fc30;
      }

      span {
        font-size: 0.8rem;
      }
    }

    h4 {
      font-size: 1.2rem;

      &:hover {
        color: $tera-black;
      }
    }

    .app-desc {
      padding: 0.5rem 1rem;
      font-weight: 300;
    }
  }

  height: 100vh;
  background: #00000040;
  z-index: 20000;
  position: fixed;
  top: 0;
  transition: 0.2s ease all;
  width: 100%;

  .side-content {
    position: absolute;
    background: white;
    height: 100%;
  }

  .close {
    position: absolute;
    top: 10px;
    cursor: pointer;
    transition: 0.4s ease all;
    font-size: 1.2rem;

    &:hover {
      transform: rotateX(20);
    }
  }

  &-nav {
    padding: 3rem 1rem;

    .user-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .user-nav-item {
        margin: 0;
        margin-top: 1rem;
      }

      &-name,
      &-email {
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-width: 180px;
        display: block;
      }

      &-email {
        font-weight: 400;
      }

      &-actions {
        .nav-action {
          font-size: 0.95rem;
          padding: 0 0.5rem;
          cursor: pointer;

          &:first-child {
            border-right: 1px solid #777;
            padding-left: 0;
          }
        }
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 1rem;
      }

      & + hr {
        border-color: #777;
      }
    }

    .nav-item {
      margin: 1.5rem 0;
      font-size: 1.2rem;
      transition: 0.2s ease all;

      svg {
        color: #777;
        margin-right: 1rem;
        font-size: 1.2rem;
      }

      &:hover {
        color: #37a000;
        transform: scale(1.05);
        margin-left: 1rem;
      }

      &.app-switch {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 1rem;
        margin: 2rem -1rem 0;
        a {
          display: block;
        }

        &:hover {
          color: #222;
          transform: scale(1);
        }

        &.buy {
          background-color: #83bc2320;
          &:hover {
            background-color: darken(#83bc2320, 50%);
          }
        }

        &.sell {
          background-color: #21c0fc20;

          &:hover {
            background-color: darken(#21c0fc20, 50%);
          }
        }
      }
    }
  }
}
